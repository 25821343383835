.contact {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1em;
  @media screen and (max-width: 816px) {
    flex-direction: column-reverse;
    align-items: center;
  }
  .contact-links {
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 300px;
    @media screen and (max-width: 816px) {
      width: 480px;
      margin-top: 1em;
    }
    @media screen and (max-width: 510px) {
      width: 360px;
      margin-top: 1em;
    }
    @media screen and (max-width: 400px) {
      width: 250px;
      margin-top: 1em;
    }
    .link-set {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.75em;
      i {
        color: #f8941e;
      }
      a {
        text-decoration: none;
        color: #808080;
        line-height: 1.5em;
        margin-left: 0.75em;
        cursor: pointer;
        p {
          margin: 0;
        }
        &:hover,
        :focus {
          color: #f8941e;
        }
      }
    }
  }
  .google-map {
    width: 480px;
    height: 200px;
    background-color: yellow;
    @media screen and (max-width: 510px) {
      width: 360px;
    }
    @media screen and (max-width: 400px) {
      width: 250px;
    }
  }
}
