.service-box {
  width: 360px;
  min-height: 540px;
  display: flex;
  flex-direction: column;
  margin: 1em;
  img {
    width: 100%;
  }
  .service-box-content {
    background-color: #fff;
    height: 100%;
    padding: 1em;
    h4 {
      margin-top: 2em;
      text-transform: uppercase;
    }
    p {
      font-size: 0.9rem;
      text-align: left;
    }
  }
}
