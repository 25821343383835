.about {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 2em 0;
  @media screen and(max-width: 1075px) {
    flex-direction: column-reverse;
    width: 480px;
  }
  @media screen and(max-width: 540px) {
    flex-direction: column-reverse;
    width: 360px;
  }
  @media screen and(max-width: 400px) {
    flex-direction: column-reverse;
    width: 300px;
  }
  @media screen and(max-width: 330px) {
    flex-direction: column-reverse;
    width: 250px;
  }
  .aboutContent {
    max-width: 480px;
    padding-right: 2em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and(max-width: 1075px) {
      align-items: center;
    }
    @media screen and(max-width: 400px) {
      padding: 1em;
    }
    h2 {
      text-align: left;
    }
    p {
      width: 100%;
      text-align: left;
      font-size: 0.9em;
    }
  }
  img {
    width: 480px;
    padding-left: 2em;
    @media screen and(max-width: 1075px) {
      padding: 0;
      width: 100%;
    }
  }
}
