.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: #fff;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  @media screen and(max-width: 1225px) {
    justify-content: flex-start;
  }
  @media screen and(max-width: 640px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header-left {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 1em;
    @media screen and(max-width: 640px) {
      margin: 0;
    }
    @media screen and(max-width: 420px) {
      margin-bottom: 0.5em;
    }
    .image-wrapper {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      @media screen and(max-width: 640px) {
        margin-top: 0.5em;
      }
      img {
        width: 200px;
        @media screen and(max-width: 640px) {
          width: 150px;
        }
      }
    }
  }

  .header-right {
    flex: 3;
    @media screen and(max-width: 1225px) {
      width: 100%;
    }
    @media screen and(max-width: 420px) {
      display: none;
    }

    .header-right-upper {
      background-color: #f8941e;
      display: flex;
      flex-direction: row;
      padding: 0.25em 0;
      @media screen and (max-width: 640px) {
        display: none;
      }
      .header-right-upper-left {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        a {
          margin-left: 1em;
          color: #fff;
          text-decoration: none;
          font-weight: 500;
          font-size: 0.8em;
          &:hover,
          &:focus {
            color: #000;
          }

          i {
            margin-right: 0.5em;
          }
        }
      }
      .header-right-upper-right {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        @media screen and (max-width: 1360px) {
          justify-content: flex-end;
        }

        a {
          text-decoration: none;
          color: #fff;
          font-size: 0.6em;
          border: 1px solid #fff;
          width: 20px;
          height: 20px;
          border-radius: 2px;
          margin-right: 1em;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover,
          &:focus {
            color: #000;
            border: 1px solid #000;
          }
        }
      }
    }
    .header-right-lower {
      background-color: #fff;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 1em 0;
        padding-left: 20em;
        @media screen and (max-width: 1100px) {
          padding-left: 0;
          justify-content: flex-end;
        }
        @media screen and(max-width: 640px) {
          justify-content: center;
        }

        a {
          text-decoration: none;
          padding: 0 1em;
          color: #000;
          font-weight: 500;

          &.active-link {
            color: #f8941e;
          }
          &:hover,
          :focus {
            color: #f8941e;
          }
        }
      }
    }
  }
}

// .header:before {
//   content:"";
//   position:absolute;
//   top:0;
//   bottom:0;
//   left: 0px;
//   width: 1000px;
//   background:#fff;
//   transform:skew(45deg);
// }
