.wideSection-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
.wideSection-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 2em 0;
}
