.slider {
  height: 800px;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  @media screen and (max-width: 1240px) {
    height: 500px;
  }
  @media screen and (max-width: 787px) {
    height: 400px;
  }
  @media screen and (max-width: 636px) {
    height: 300px;
  }
  @media screen and (max-width: 480px) {
    height: 200px;
  }
}

.slider-single-slide {
  position: relative;
  max-height: 800px;
  width: 100%;
  display: flex !important;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  @media screen and (max-width: 1240px) {
    max-height: 500px;
  }
  @media screen and (max-width: 787px) {
    max-height: 400px;
  }
  @media screen and (max-width: 636px) {
    max-height: 300px;
  }
  @media screen and (max-width: 480px) {
    max-height: 200px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .image-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .slide-content {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      color: #fff;
      font-size: 2em;
      margin-bottom: 0.5em;
      max-width: 240px;
      text-align: center;
    }

    a {
      color: #fff;
      background-color: #f8941e;
      border: none;
      padding: 0.5em;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.prevArrow {
  position: absolute;
  left: 0;
  top: 40%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 400;
  height: 80px;
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  i {
    color: #fff;
  }
  @media screen and (max-width: 480px) {
    top: 30%;
  }
}
.nextArrow {
  position: absolute;
  right: 0;
  top: 40%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 400;
  height: 80px;
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  i {
    color: #fff;
  }
  @media screen and (max-width: 480px) {
    top: 30%;
  }
}
