.footer {
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1075px) {
    flex-direction: column;
    padding-bottom: 1em;
  }
  p {
    margin-right: 1em;
  }
  .icon-set {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &:hover a,
    &:hover i,
    &:focus a,
    &:focus i {
      color: #f8941e;
    }
    a {
      margin: 0.5em 1em 0.5em 0.5em;
      text-decoration: none;
      color: #fff;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      width: unset;
      @media screen and (max-width: 1075px) {
        width: 230px;
        text-align: left;
        flex-direction: column;
      }
      p {
        margin: 0 0.3em;
      }
    }
  }
}
