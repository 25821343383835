.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
  h2 {
    position: relative;
    text-transform: uppercase;
    color: #000;
    font-weight: 500;
    margin-bottom: 0.5em;
    span {
      &:first-child {
        color: #f8941e;
        margin-right: 0.3em;
      }
    }
  }
  .title-underline {
    width: 30px;
    height: 2px;
    background-color: #f8941e;
  }
}
